import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import type { CreateMonitoringGroupRequest, MonitoringGroupResponse } from '~/clients'
const schema = z.object({
  code: z.string(),
  isActive: z.boolean(),
  name: z.string(),
})

export const useManageMonitoringGroups = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateMonitoringGroupRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<MonitoringGroupResponse, CreateMonitoringGroupRequest>(
    type,
    id,
    'monitoring-groups',
    defaults,
    'Process Monitoring Group',
    mutationOptions,
    schema,
  )
}
