export const Environment = {
  production: 'app.gmpplus.org',
  development: 'app-dev.gmpplus.org',
  test: 'app-test.gmpplus.org',
  acceptance: 'app-acc.gmpplus.org',
}

export enum EnvironmentTypes {
  portal = 'portal',
  management = 'management',
  academy = 'academy',
  cms = 'cms',
}
