export const convertArrayToString = (array?: string[] | null): string => {
  return array?.join(', ') || ''
}

export const convertStringToArray = (string?: string | null): string[] => {
  if (!string || string === '') return []

  return string.split(',').map((item) => item.trim())
}

export type InputOptions = {
  disabled?: boolean
  errors?: string[]
  clearable?: boolean
  required?: boolean
  width?: string
  errorBorderOnly?: boolean
  absoluteError?: boolean
}
