export enum AuthRole {
  QRAY_GMP = 'QRAY_GMP',
  PORTAL_COMPANY = 'PORTAL_COMPANY',
  QRAY_FSP = 'QRAY_FSP',
  QRAY_MON = 'QRAY_MON',
  PORTAL_EWS = 'PORTAL_EWS',
  PORTAL_TCFSP = 'PORTAL_TCFSP',
  PORTAL_COORDINATOR = 'PORTAL_COORDINATOR',
  QRAY_MANAGER = 'QRAY_GROUPMANAGER',
  authenticated = 'authenticated',
}
