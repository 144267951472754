import { useApiClient } from '~/composables/api/api'
import { z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import type { UnwrapRef } from 'vue'
import { useQueryClient, useMutation } from '@tanstack/vue-query'
import _ from 'lodash'

const schema = z
  .object({
    analysisSetItems: z.array(
      z.object({
        hazardId: z.number(),
        methodId: z.number().optional().nullable(),
        detectionBorderValue: z.number(),
      }),
    ),
  })
  .transform((data) => ({
    analysisSetItems: data.analysisSetItems,
  }))

export const useUpdatePortalAnalysisSetUndesirableSubstances = (id: number) => {
  const formStore = useFormStore()
  const queryClient = useQueryClient()
  const { data: analysisSet } = useGetPortalAnalysisSet(id)

  const data = reactive<{ analysisSetItems: AnalysisSetItem[] }>({
    analysisSetItems: _.clone(analysisSet.value!.analysisSetItems).map((item, index) => ({
      hazardName: item.hazard.nameAsJson,
      hazardUnit: item.hazard.unitNameAsJson,
      hazardId: item.hazardId,
      methodId: item.methodId,
      detectionBorderValue: item.detectionBorderValue,
    })),
  })

  type AnalysisSetItem = {
    hazardName: object | JsonTranslation | undefined
    hazardUnit?: object | JsonTranslation | undefined
    hazardId: number
    methodId: number
    detectionBorderValue: number
  }

  const addAnalysisSetItem = (item: AnalysisSetItem) => {
    data.analysisSetItems.push({
      hazardName: item.hazardName,
      hazardUnit: item.hazardUnit,
      hazardId: item.hazardId,
      methodId: item.methodId,
      detectionBorderValue: item.detectionBorderValue,
    })
  }

  const removeAnalysisSetItem = (hazardId: number, methodId: number) => {
    const index = data.analysisSetItems.findIndex(
      (analysisSetItem) =>
        analysisSetItem.hazardId === hazardId && analysisSetItem.methodId === methodId,
    )
    if (index !== -1) {
      data.analysisSetItems.splice(index, 1)
    }
  }

  const isDirty = ref(false)

  watch(
    [data],
    () => {
      isDirty.value = true
      formStore.setDirty(isDirty.value)
    },
    { deep: true },
  )

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation({
    mutationFn: async (mutationData: UnwrapRef<typeof data>) => {
      schema.parse(mutationData)

      const uploadData = mutationData.analysisSetItems.map(
        ({ hazardName, hazardUnit, ...rest }, index) => ({ ...rest, sortOrder: index + 1 }),
      )

      return await useApiClient<Promise<AnalysisSetResponse>>(`/portal/analysis-sets/${id}`, {
        method: 'PATCH',
        body: {
          name: analysisSet.value!.name,
          id: analysisSet.value!.id,
          locationId: analysisSet.value!.locationId,
          analysisSetItems: uploadData,
        },
      })
    },
    onSuccess: async () => {
      // Invalidate the query to re-fetch the analysis set
      await queryClient.invalidateQueries({
        queryKey: ['analysis-set', id],
      })
      formStore.reset()
    },
  })

  const errors = computed(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError) return mutateError.value.data
    return mutateError.value
  })

  const zodErrors = ref()

  const submit = async () => {
    // Todo: use useForm
    const validationResult = schema.safeParse(data)
    if (validationResult.error) {
      zodErrors.value = validationResult.error.format()
      return
    }

    await mutateAsync(data)
  }

  return {
    data,
    submit,
    isPending,
    isDirty,
    errors: zodErrors || errors,
    addAnalysisSetItem,
    removeAnalysisSetItem,
  }
}
