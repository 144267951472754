import type { ProductResponse } from '~/clients'
import { z } from 'zod'
import { useApiClient } from '~/composables/api/api'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { useErrors } from '~/composables/useErrors'
import { toIsoDate, zodTranslation } from '~/utils'
import { convertStringToArray } from '~/utils/inputHelper'

const schema = z.object({
  id: z.number().optional(),
  code: z.string().min(1).max(8),
  isActiveMonitoring: z.boolean(),
  isActiveForRiskAssessment: z.boolean(),
  isEuProduct: z.boolean(),
  expirationDate: z.coerce
    .date()
    .optional()
    .nullable()
    .transform((val) => (val ? toIsoDate(val) : null)),
  nameAsJson: zodTranslation('Name is required.'),
  productGroupId: z.number().nullable().optional(),
  definitionAsJson: zodTranslation('Description is required.'),
  synonymsAsJson: zodTranslation('Synonym is required.').transform((val) => {
    Object.keys(val).forEach((key) => {
      val[key] = convertStringToArray(val[key])
    })
    return val
  }),
})

export const useManageProduct = (
  type: 'create' | 'update',
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  const { mutateAsync: updateMutate, error: updateMutateError } = useMutation({
    mutationFn: async (data: z.input<typeof schema>) => {
      const validatedData = schema.parse(data)

      return await useApiClient<ProductResponse>(`/products/${id}`, {
        method: 'PATCH',
        body: validatedData,
      })
    },
    ...mutationOptions,
  })

  const { mutateAsync: createMutate, error: createMutateError } = useMutation({
    mutationFn: async (data: z.input<typeof schema>) => {
      const validatedData = schema.parse(data)

      return await useApiClient<ProductResponse>(`/products`, {
        method: 'POST',
        body: validatedData,
      })
    },
    ...mutationOptions,
  })

  const updateErrors = useErrors(schema, updateMutateError)
  const createErrors = useErrors(schema, createMutateError)

  const updateProduct = async (data: z.input<typeof schema>) => await updateMutate(data)

  const createProduct = async (data: z.input<typeof schema>) => await createMutate(data)

  return {
    mutate: type === 'create' ? createProduct : updateProduct,
    errors: type === 'create' ? createErrors : updateErrors,
    schema,
  }
}
