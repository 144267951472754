import axios, { type AxiosRequestConfig, type AxiosResponse, isAxiosError } from 'axios'
import type { AbortSignal } from '@whatwg-node/node-fetch'
import { Client, type ContentResult } from '~/clients/index'
import { convertLineBreaks } from '~/utils'

export class ApiClient extends Client {
  constructor(fakeHeader?: string, apiBaseUrl?: string) {
    let headers = {}

    if (fakeHeader) headers = { 'x-ms-client-principal': fakeHeader }

    const axiosClient = axios.create({
      transformResponse: [
        (data) => {
          // Convert all line breaks to \n
          return convertLineBreaks(data)
        },
      ],
      headers,
    })

    axiosClient.interceptors.request.use(
      (request) => request,
      (error) => {
        // useToast().error('An error occurred performing the operation.')
        error.statusCode = error.response?.status
        error.data = error.response?.data
        error.type = 'onResponseError'

        return Promise.reject(error)
      },
    )

    axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        // useToast().error('An error occurred performing the operation.')

        error.statusCode = error.response?.status
        error.data = error.response?.data
        error.type = 'onResponseError'

        return Promise.reject(error)
      },
    )

    super(`${apiBaseUrl ?? '/api'}`, axiosClient)
  }

  public getBaseUrl(): string {
    return this.baseUrl
  }

  public getInstance() {
    return this.instance
  }

  listPortalProductsCsv(language: string, signal?: AbortSignal): Promise<ContentResult> {
    return this.instance.get(`${this.baseUrl}/portal/products/csv/${language}`, { signal })
  }

  listPortalCountriesCsv(language: string, signal?: AbortSignal): Promise<ContentResult> {
    return this.instance.get(`${this.baseUrl}/portal/countries/csv/${language}`, { signal })
  }

  listPortalMethodsCsv(language: string, signal?: AbortSignal): Promise<ContentResult> {
    return this.instance.get(`${this.baseUrl}/portal/methods/csv/${language}`, {
      signal,
    })
  }

  listPortalHazardsCsv(language: string, signal?: AbortSignal): Promise<ContentResult> {
    return this.instance.get(`${this.baseUrl}/portal/hazards/csv/${language}`, {
      signal,
    })
  }

  listPortalLocationsLaboratoriesCsv(
    language: string,
    signal?: AbortSignal,
  ): Promise<ContentResult> {
    return this.instance.get(`${this.baseUrl}/portal/locations/laboratories/csv/${language}`, {
      signal,
    })
  }

  listPortalLocationsCsv(language: string, signal?: AbortSignal): Promise<ContentResult> {
    return this.instance.get(`${this.baseUrl}/portal/locations/csv/${language}`, {
      signal,
    })
  }

  /**
   * @param path (optional)
   * @param signal
   * @param isBlob
   * @return Returns any response from the API
   */
  readPortalProxyRequest(
    path?: string | undefined,
    signal?: AbortSignal | undefined,
    isBlob?: boolean,
  ): Promise<any> {
    let url_ = this.baseUrl + '/portal/proxy/stipp/{path}'
    if (path !== null && path !== undefined) {
      url_ = url_.replace('{path}', encodeURIComponent('' + path))
    } else {
      url_ = url_.replace('/{path}', '')
      url_ = url_.replace(/[?&]$/, '')
    }

    let options_: AxiosRequestConfig = {
      ...(isBlob ? { responseType: 'blob' } : {}),
      method: 'GET',
      url: url_,
      headers: {
        Accept: 'application/json',
      },
      signal,
    }

    return this.instance
      .request(options_)
      .catch((_error: any) => {
        if (isAxiosError(_error) && _error.response) {
          return _error.response
        } else {
          throw _error
        }
      })
      .then((_response: AxiosResponse) => {
        return isBlob ? _response : this.processReadPortalProxyRequest(_response)
      })
  }
}
