import { useApiClient } from '~/composables/api/api'
import { z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import type { UnwrapRef } from 'vue'

const stepOneSchema = z
  .object({
    name: z.string().min(1),
    locationId: z.number().min(1),
  })
  .transform((data) => ({
    name: data.name,
    locationId: data.locationId,
  }))

const stepTwoSchema = z
  .object({
    analysisSetItems: z.array(
      z.object({
        hazardId: z.number(),
        methodId: z.number().optional().nullable(),
        detectionBorderValue: z.number(),
      }),
    ),
  })
  .transform((data) => ({
    analysisSetItems: data.analysisSetItems,
  }))

const combinedSchema = stepOneSchema.and(stepTwoSchema)
type CombinedType = z.infer<typeof combinedSchema>

export const useCreatePortalAnalysisSet = () => {
  const authStore = useAuthStore()

  const data = reactive<{
    name: string
    locationId: number | undefined
    analysisSetItems: AnalysisSetItem[]
  }>({
    name: '',
    locationId: authStore?.location || undefined,
    analysisSetItems: [],
  })

  const validationErrors = reactive<{
    stepOne: undefined | Record<string, string[]>
    stepTwo: undefined | Record<string, string[]>
  }>({
    stepOne: undefined,
    stepTwo: undefined,
  })

  type AnalysisSetItem = {
    hazardName: object | JsonTranslation | undefined
    hazardUnit: object | JsonTranslation | undefined
    hazardId: number
    methodId?: number | null
    detectionBorderValue?: number | null
  }

  const addAnalysisSetItem = (item: AnalysisSetItem) => {
    data.analysisSetItems.push({
      hazardName: item.hazardName,
      hazardUnit: item.hazardUnit,
      hazardId: item.hazardId,
      methodId: item.methodId,
      detectionBorderValue: item.detectionBorderValue,
    })
  }

  const removeAnalysisSetItem = (hazardId: number, methodId: number) => {
    const index = data.analysisSetItems.findIndex(
      (analysisSetItem) =>
        analysisSetItem.hazardId === hazardId && analysisSetItem.methodId === methodId,
    )
    if (index !== -1) {
      data.analysisSetItems.splice(index, 1)
    }
  }

  const isDirty = ref(false)

  watch(
    [data],
    () => {
      isDirty.value = true
    },
    { deep: true },
  )

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation({
    mutationFn: async (mutationData: UnwrapRef<typeof data>) => {
      const uploadData: CombinedType = {
        ...mutationData,
        analysisSetItems: mutationData.analysisSetItems.map(
          ({ hazardName, hazardUnit, ...rest }, index) => ({ ...rest, sortOrder: index + 1 }),
        ),
      }

      return await useApiClient<Promise<AnalysisSetResponse>>(`/portal/analysis-sets`, {
        method: 'POST',
        body: uploadData,
      })
    },
    onSuccess(data) {
      navigateTo(`/portal/risk-management/monitoring-database/analysis-sets/${data.id}`)
    },
  })

  const errors = computed(() => {
    if (validationErrors?.stepOne) return validationErrors.stepOne
    if (validationErrors?.stepTwo) return validationErrors.stepTwo
    if (mutateError.value instanceof FetchError) return mutateError.value.data
    return mutateError.value
  })

  const validateStepOne = () => {
    try {
      stepOneSchema.parse(data)
      validationErrors.stepOne = undefined
      return true
    } catch (e) {
      if (e instanceof ZodError) {
        validationErrors.stepOne = e.format()
      }
      return false
    }
  }

  const validateStepTwo = () => {
    try {
      stepTwoSchema.parse(data)
      validationErrors.stepTwo = undefined
      return true
    } catch (e) {
      if (e instanceof ZodError) {
        validationErrors.stepTwo = e.format()
      }
      return false
    }
  }

  const submit = async () => await mutateAsync(data)

  return {
    data,
    addAnalysisSetItem,
    removeAnalysisSetItem,
    submit,
    isPending,
    isDirty,
    errors,
    validateStepOne,
    validateStepTwo,
  }
}
