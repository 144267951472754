import { AxiosError } from 'axios'

interface OriginalObject {
  [key: string]: string[]
}

interface ConvertedObject {
  [key: string]: { _errors: string[] }
}

const convertFirstLower = (string: string): string => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

const convertToObject = (newKeyParts: string[], originalObject: OriginalObject, key: string) => {
  return newKeyParts.reduceRight(
    (acc: { _errors: string[] }, keyPart) => {
      return {
        [keyPart]: acc,
      }
    },
    { _errors: originalObject[key] },
  )
}

export const formatErrors = (originalObject: OriginalObject): ConvertedObject => {
  const converted: ConvertedObject = {}

  // temp patch for ApiException and AxiosError
  if (
    originalObject instanceof ApiException ||
    originalObject instanceof AxiosError ||
    originalObject.type === 'onResponseError' ||
    originalObject.type === 'onError'
  ) {
    originalObject = originalObject.result
  }

  Object.keys(originalObject).forEach((key: string) => {
    const newKey = convertFirstLower(key)

    const newKeyParts = newKey.split('.')
    if (newKeyParts.length > 1) {
      const newKeyObject = convertToObject(newKeyParts, originalObject, key)
      converted[newKeyParts[0]] = newKeyObject[newKeyParts[0]]
      return
    }

    converted[newKey] = {
      _errors: originalObject[key],
    }
  })

  return converted
}
