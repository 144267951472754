import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'
import type { CreateMethodRequest, MethodResponse } from '~/clients'

const schema = z.object({
  nameAsJson: zodTranslation('Name is required.'),
  code: z.string().min(1).max(10),
  descriptionAsJson: zodTranslation('Description is required.'),
})

export const useManageMethods = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateMethodRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<MethodResponse, CreateMethodRequest>(
    type,
    id,
    'methods',
    defaults,
    'Method',
    mutationOptions,
    schema,
  )
}
