export enum SampleStatus {
  None = 'None',
  Analysed = 'Analysed',
  Copied = 'Copied',
  Elapsed = 'Elapsed',
  Final = 'Final',
  Taken = 'Taken',
  Planned = 'Planned',
}

export enum SampleLevel {
  None = 'None',
  Own = 'Own',
  Community = 'Community',
  Copied = 'Copied',
  Group = 'Group',
}
