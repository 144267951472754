import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'
import type {
  CreateProcessingAidRequest,
  ProcessingAidResponse,
  UpdateProcessingAidRequest,
} from '~/clients'

const schema = z
  .object({
    id: z.number().optional(),
    groupId: z.number(),
    nameAsJson: zodTranslation('Description is required.'),
  })
  .transform((data) => {
    return {
      processingAidGroupsProcessingAids: [
        { processingAidId: data.id, processingAidGroupId: data.groupId },
      ],
      nameAsJson: data.nameAsJson,
    }
  })

export const useManageProcessingAids = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateProcessingAidRequest | UpdateProcessingAidRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<
    ProcessingAidResponse,
    CreateProcessingAidRequest | UpdateProcessingAidRequest
  >(type, id, 'processing-aids', defaults, 'Processing Aid', mutationOptions, schema)
}
