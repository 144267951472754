export const navigateToLogin = (path: string) => {
  if (!process.dev)
    return navigateTo(`/.auth/login/aadb2c?post_login_redirect_uri=${path}`, {
      external: true,
    })
}

export const navigateToLogout = (path?: string) => {
  return navigateTo(`/.auth/logout?post_logout_redirect_uri=${path ?? encodeURI('/')}`, {
    external: true,
  })
}
