import { useApiClient } from '~/composables/api/api'
import { z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import type { UnwrapRef } from 'vue'
import { useQueryClient, useMutation } from '@tanstack/vue-query'
import zod from '~/plugins/zod'

const schema = z
  .object({
    id: z.number(),
    name: z.string().min(1),
    locationId: z.number().min(1),
  })
  .transform((data) => ({
    name: data.name,
    locationId: data.locationId,
  }))

export const useUpdatePortalAnalysisSetGeneralInfo = (id: number) => {
  const formStore = useFormStore()
  const queryClient = useQueryClient()
  const { data: analysisSet } = useGetPortalAnalysisSet(id)

  const data = reactive<z.input<typeof schema>>({
    id: analysisSet.value?.id || 0,
    locationId: analysisSet.value?.locationId || 0,
    name: analysisSet.value?.name || '',
  })

  const isDirty = ref(false)

  watch(
    [data],
    () => {
      isDirty.value = true
      formStore.setDirty(isDirty.value)
    },
    { deep: true },
  )

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation({
    mutationFn: async (mutationData: UnwrapRef<typeof data>) => {
      schema.parse(mutationData)
      return await useApiClient<Promise<AnalysisSetResponse>>(`/portal/analysis-sets/${id}`, {
        method: 'PATCH',
        body: { ...mutationData, analysisSetItems: analysisSet.value!.analysisSetItems || [] },
      })
    },
    onSuccess: async () => {
      // Invalidate the query to re-fetch the analysis set
      await queryClient.invalidateQueries({
        queryKey: ['analysis-set', id],
      })
      formStore.reset()
    },
  })

  const errors = computed(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError) return mutateError.value.data
    return mutateError.value
  })

  const submit = async () => await mutateAsync(data)

  return {
    data,
    submit,
    isPending,
    isDirty,
    errors,
  }
}
