import { useApiClient } from '~/composables/api/api'
import { z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import { useQueryClient, useMutation } from '@tanstack/vue-query'

const schema = z.object({
  id: z.number(),
  status: z.string(),
})

export const useUpdateStatusMonitoringGroupLocations = () => {
  const formStore = useFormStore()
  const queryClient = useQueryClient()

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutationWithToast({
    mutationFn: async (mutationData: z.input<typeof schema>) => {
      schema.parse(mutationData)

      return await useApiClient<Promise<LocationsMonitoringGroupResponse>>(
        `/portal/locations-monitoring-groups/${mutationData.id}/status`,
        {
          method: 'PATCH',
          body: mutationData,
        },
      )
    },
    onSuccess: async () => {
      // Invalidate the query to re-fetch the analysis set
      await queryClient.invalidateQueries({ queryKey: ['monitoring-group-locations'] })
      await queryClient.invalidateQueries({ queryKey: ['location-monitoring-groups'] })
      formStore.reset()
    },
  })

  const errors = computed(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError) return mutateError.value.data
    return mutateError.value
  })

  const submit = async (data) => await mutateAsync(data)

  return {
    submit,
    isPending,
    errors,
  }
}
