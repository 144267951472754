import type { DehydratedState, VueQueryPluginOptions } from '@tanstack/vue-query'
import { VueQueryPlugin, QueryClient, hydrate, dehydrate, QueryCache } from '@tanstack/vue-query'
// Nuxt 3 app aliases
import { defineNuxtPlugin, useState } from '#imports'

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>('vue-query')
  // Modify your Vue Query global settings here
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 100,
        refetchOnWindowFocus: false,
        retry(failureCount) {
          return failureCount < 0
        },
        throwOnError: (error) => {
          error.from = 'query'
          console.debug('Query error', error)
          throw createError(error)
        },
      },
      mutations: {
        throwOnError: (error) => {
          error.from = 'mutation'
          console.debug('Mutation error')
          throw createError(error)
        },
      },
    },
    queryCache: new QueryCache({}),
  })
  const options: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, options)

  if (process.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient)
    })
  }

  if (process.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value)
    })
  }
})
