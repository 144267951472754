import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'
import type { CreateProductGroupRequest, ProductGroupResponse } from '~/clients'

const schema = z.object({
  code: z.string(),
  category: z.string(),
  descriptionAsJson: zodTranslation('Description is required.'),
})

export const useManageProductGroups = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateProductGroupRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<ProductGroupResponse, CreateProductGroupRequest>(
    type,
    id,
    'product-groups',
    defaults,
    'Product Group',
    mutationOptions,
    schema,
  )
}
