import { z, ZodError, ZodObject } from 'zod'
import { FetchError } from 'ofetch'

export const useErrors = (
  schema: ZodObject<any>,
  mutateError: { value: ZodError | FetchError | any },
) => {
  return computed<z.inferFormattedError<typeof schema>>(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError) return mutateError.value.data

    return mutateError.value
  })
}
