import { ToastAction } from '#components'
import type { ToastOptions } from 'vue3-toastify'

export const useToast = () => {
  const { $toast } = useNuxtApp()
  return {
    error: (
      message: string,
      action?: { label: string; click: () => Promise<void> | void },
      options?: ToastOptions,
    ) =>
      $toast.error(ToastAction, {
        data: {
          message,
          action,
        },
        ...options,
        autoClose: action ? false : 2000,
      }),
    success: (
      message: string,
      action?: { label: string; click: () => Promise<void> | void },
      options?: ToastOptions,
    ) =>
      $toast.success(ToastAction, {
        data: {
          message,
          action,
        },
        ...options,
        autoClose: action ? false : 5000,
      }),
    info: (
      message: string,
      action?: { label: string; click: () => Promise<void> | void },
      options?: ToastOptions,
    ) =>
      $toast.info(ToastAction, {
        data: {
          message,
          action,
        },
        ...options,
        autoClose: action ? false : 2000,
      }),
    warning: (
      message: string,
      action?: { label: string; click: () => Promise<void> | void },
      options?: ToastOptions,
    ) =>
      $toast.warning(ToastAction, {
        data: {
          message,
          action,
        },
        ...options,
        autoClose: action ? false : 2000,
      }),
  }
}
