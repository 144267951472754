import _ from 'lodash'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const fromSessions = useSessionStorage<string | undefined>(from.path, undefined)
  const toSessions = useSessionStorage<string | undefined>(to.path, undefined)

  if (!_.isEmpty(toSessions.value) && to.path !== from.path) {
    to.query = JSON.parse(toSessions.value!)
    toSessions.value = undefined
    return navigateTo(to)
  }

  if (to.path.split('/').length > from.path.split('/').length && !_.isEmpty(from.query)) {
    fromSessions.value = JSON.stringify(from.query)
  }

  return
})
