<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      size?: 'small' | 'regular' | 'large' | 'icon' | 'inline'
      color?:
        | 'primary'
        | 'secondary'
        | 'affirmative'
        | 'critical'
        | 'textual'
        | 'textual-borderless'
        | 'icon'
        | 'link'
        | 'tab'
      type?: 'button' | 'submit' | 'reset'
      disabled?: boolean
    }>(),
    {
      color: 'primary',
      size: 'regular',
      disabled: false,
      type: 'button',
    },
  )

  const emit = defineEmits<{
    (e: 'click'): void
  }>()
</script>

<template>
  <button
    @click.stop="emit('click')"
    :type="props.type"
    :disabled="disabled"
    class="group gap-2 flex items-center focus:ring-4 focus:outline-none focus:ring-focused transition-all duration-250 min-w-fit"
    :class="{
      'bg-none text-black hover:text-primary-light focus:text-black active:text-black disabled:bg-disabled-regular disabled:text-disabled-dark border-none rounded-none focus:ring-0':
        props.color === 'link',
      'bg-primary text-white hover:bg-primary-light active:bg-primary-dark disabled:bg-disabled-regular disabled:text-disabled-dark':
        props.color === 'primary',
      'border border-secondary border-1 text-primary bg-white hover:bg-neutral-100 active:bg-secondary disabled:bg-disabled-regular disabled:text-disabled-dark':
        props.color === 'secondary',
      'bg-affirmative text-white hover:bg-affirmative-light active:bg-affirmative-dark disabled:bg-disabled-regular disabled:text-disabled-dark':
        props.color === 'affirmative',
      'bg-critical text-white hover:bg-critical-light active:bg-critical-dark disabled:bg-disabled-regular  disabled:text-disabled-dark':
        props.color === 'critical',
      'bg-none text-primary border border-disabled hover:text-primary-light focus:text-black active:text-black disabled:bg-disabled-regular disabled:text-disabled-dark':
        props.color === 'textual',
      'bg-none text-primary hover:text-primary-light focus:text-black active:text-black  disabled:text-disabled-dark':
        props.color === 'textual-borderless',
      'bg-none text-none hover:text-primary-light focus:text-gray-300 active:text-gray-300 disabled:bg-disabled-regular disabled:text-disabled-dark rounded-md w-full justify-center':
        props.color === 'tab',
      'text-primary disabled:bg-none': props.color === 'icon',
      'p-0 text-base': props.size === 'inline',
      'px-4 py-1.5 h-8 text-sm': props.size === 'small',
      'px-4 py-2.5 h-10 text-base': props.size === 'regular',
      'px-6 py-3 h-12 text-base': props.size === 'large',
      'mx-2 p-2': props.size === 'icon',
      'rounded-full': props.color !== 'icon',
    }"
  >
    <slot name="prepend-icon" />
    <slot />
    <slot name="append-icon"></slot>
  </button>
</template>
