import type { HazardResponse } from '~/clients'
import { z } from 'zod'
import { useApiClient } from '~/composables/api/api'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import { useErrors } from '~/composables/useErrors'
import { zodTranslation } from '~/utils'

export const manageHazardSchema = z.object({
  code: z.string().min(1).max(8),
  sortOrder: z.number().nullish(),
  standardReference: z.string().nullable().optional(),
  analysisBase: z.nativeEnum(HazardResponseAnalysisBase).optional(),
  severity: z.nativeEnum(HazardResponseSeverity).optional(),
  category: z.nativeEnum(HazardResponseCategory),
  hazardType: z.nativeEnum(HazardResponseHazardType).optional(),
  nameAsJson: zodTranslation('Name is required.'),
  descriptionAsJson: zodTranslation('Description is required.', true),
  isActiveMonitoring: z.boolean().optional(),
  isActiveForRiskAssessment: z.boolean().optional(),
  moistureContentConversion: z.boolean().optional(),
  parentHazardId: z.number().optional().nullish(),
  unitId: z.number().optional(),
})

export const useManageHazard = (
  type: 'create' | 'update',
  id?: number,
  mutationOptions?: MutationObserverOptions<
    HazardResponse,
    unknown,
    z.input<typeof manageHazardSchema>,
    unknown
  >,
) => {
  const { mutateAsync: updateMutate, error: updateMutateError } = useMutation({
    mutationFn: async (data: z.input<typeof manageHazardSchema>) => {
      const validatedData = manageHazardSchema.parse(data)

      return await useApiClient<HazardResponse>(`/hazards/${id}`, {
        method: 'PATCH',
        body: validatedData,
      })
    },
    ...mutationOptions,
  })

  const { mutateAsync: createMutate, error: createMutateError } = useMutation({
    mutationFn: async (data: z.input<typeof manageHazardSchema>) => {
      const validatedData = manageHazardSchema.parse(data)

      return await useApiClient<HazardResponse>(`/hazards`, {
        method: 'POST',
        body: validatedData,
      })
    },
    ...mutationOptions,
  })

  const updateErrors = useErrors(manageHazardSchema, updateMutateError)
  const createErrors = useErrors(manageHazardSchema, createMutateError)

  const update = async (data: z.input<typeof manageHazardSchema>) => await updateMutate(data)

  const create = async (data: z.input<typeof manageHazardSchema>) => await createMutate(data)

  return {
    mutate: type === 'create' ? create : update,
    errors: type === 'create' ? createErrors : updateErrors,
    schema: manageHazardSchema,
  }
}
