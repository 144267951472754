import { useApiClient } from '~/composables/api/api'
import { z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import type { RiskAssessmentsProductResponse, UnitResponse } from '~/clients'
import { useQueryClient } from '@tanstack/vue-query'
import _ from 'lodash'

const schema = z
  .object({
    id: z.number(),
    versionId: z.number(),
    products: z
      .array(
        z.object({
          id: z.number().optional(), //optional when create
          productId: z.number(),
        }),
      )
      .min(1),
  })
  .transform((data) => ({
    id: data.id,
    versionId: data.versionId,
    products: data.products,
  }))

export const useUpdateRiskAssessmentProducts = async (id: number) => {
  const formStore = useFormStore()

  const queryClient = useQueryClient()
  const { data: riskAssessment } = useGetRiskAssessment(id)

  await queryClient.ensureQueryData({ queryKey: ['risk-assessments', id] })

  const data = reactive<z.input<typeof schema>>({
    id: riskAssessment.value!.id,
    products: _.clone(riskAssessment.value!.riskAssessmentVersions[0].riskAssessmentsProducts),
    versionId: riskAssessment.value!.riskAssessmentVersions[0].id,
  })

  const addProduct = (productId: number) => {
    data.products.push({ productId })
  }

  const removeProduct = (productId: number) => {
    const index = data.products.findIndex((product) => product.productId === productId)
    data.products.splice(index, 1)
  }

  const isDirty = ref(false)

  watch(isDirty, () => {
    formStore.setDirty(isDirty.value)
  })

  watch(
    data,
    () => {
      isDirty.value = true
    },
    { deep: true },
  )

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation(
    {
      mutationFn: async (data: z.input<typeof schema>) => {
        const validatedData = schema.parse(data)

        //only add products that are not already in the existing riskAssessment
        const productsToAdd = _.uniq(
          _.difference(
            validatedData.products.filter((p) => p.id === undefined).map((p) => p.productId),
            riskAssessment.value!.riskAssessmentVersions[0].riskAssessmentsProducts.map(
              (p) => p.productId,
            ),
          ),
        )

        //only remove products that are not in the new data and are unique from eachother, should return an array of unique objects
        const productsToRemove = _.uniq(
          _.difference(
            riskAssessment.value!.riskAssessmentVersions[0].riskAssessmentsProducts.map(
              (p) => p.productId,
            ),
            validatedData.products.map((p) => p.productId),
          ).map((productId) =>
            riskAssessment.value!.riskAssessmentVersions[0].riskAssessmentsProducts.find(
              (p) => p.productId === productId,
            ),
          ),
        )

        const add = (productId) =>
          useApiClient<RiskAssessmentsProductResponse>(
            `/risk-assessments/${data.id}/versions/${data.versionId}/products`,
            {
              method: 'POST',
              body: {
                productId: productId,
                riskAssessmentVersionId: data.versionId,
              },
            },
          )

        const remove = (product) =>
          useApiClient<RiskAssessmentsProductResponse>(
            `/risk-assessments/${data.id}/versions/${data.versionId}/products/${product.id}`,
            {
              method: 'DELETE',
            },
          )

        return Promise.allSettled([productsToAdd.map(add), productsToRemove.map(remove)])
      },
      async onSuccess(data) {
        await queryClient.invalidateQueries({
          queryKey: ['risk-assessments', riskAssessment.value!.id],
        })
        formStore.reset()
      },
    },
    queryClient,
  )

  const errors = computed<z.inferFormattedError<typeof schema>>(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError) return mutateError.value.data

    return mutateError.value
  })

  const submit = async () => await mutateAsync(data)

  return {
    addProduct,
    removeProduct,
    data,
    submit,
    isPending,
    isDirty,
    error: errors,
  }
}
