import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'

const schema = z.object({
  controlMeasureGroupId: z.number(),
  nameAsJson: zodTranslation('Name is required.'),
  descriptionAsJson: zodTranslation('Description is required.'),
})

export const useManageControlMeasure = (
  type: 'create' | 'update',
  defaults: MaybeRefDeep<CreateControlMeasureRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<ControlMeasureResponse, CreateControlMeasureRequest>(
    type,
    id,
    'control-measures',
    defaults,
    'Control Measure',
    mutationOptions,
    schema,
  )
}
