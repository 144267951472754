import { z } from 'zod'
import { useApiClient } from '~/composables/api/api'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { formatErrors } from '~/utils'
import { useForm } from '~/composables/useForm'
import type { CreateProcessingAidRequest } from '~/clients'
import { ref } from 'vue'

export const useManageApi = <T, T2>(
  type: 'create' | 'update',
  id?: number | string,
  endpoint: string,
  defaults: MaybeRefDeep<T2>,
  name: string,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
  schema?: z.ZodObject<any>,
) => {
  const isSaving = ref(false)
  const formStore = useFormStore()
  const toast = useToast()
  const { t } = useI18n()

  const { mutateAsync: updateMutate, error: updateMutateError } = useMutationWithToast(
    {
      mutationFn: async (data: z.input<typeof schema>) => {
        const validatedData = schema.parse(data)
        return await useApiClient<T>(`/${endpoint}/${id}`, {
          method: 'PATCH',
          body: validatedData,
        })
      },
      onMutate: () => (isSaving.value = true),
      onSettled: () => (isSaving.value = false),
      ...mutationOptions,
    },
    undefined,
    {
      pending: t(`toasts.update.pending`, { name }),
      success: t(`toasts.update.success`, { name }),
      error: t(`toasts.update.error`, { name }),
    },
  )

  const { mutateAsync: createMutate, error: createMutateError } = useMutationWithToast(
    {
      mutationFn: async (data: z.input<typeof schema>) => {
        const validatedData = schema.parse(data)
        return await useApiClient<T>(`/${endpoint}`, {
          method: 'POST',
          body: validatedData,
        })
      },
      onMutate: () => (isSaving.value = true),
      onSettled: () => (isSaving.value = false),
      ...mutationOptions,
    },
    undefined,
    {
      pending: t(`toasts.create.pending`, { name }),
      success: t(`toasts.create.success`, { name }),
      error: t(`toasts.create.error`, { name }),
    },
  )

  const updateErrors = useErrors(schema, updateMutateError)
  const createErrors = useErrors(schema, createMutateError)

  const update = async (data: z.input<typeof schema>) => await updateMutate(data)
  const create = async (data: z.input<typeof schema>) => await createMutate(data)

  const apiErrors = type === 'create' ? createErrors : updateErrors

  const { form, isDirty, errors, submit } = useForm<CreateProcessingAidRequest>(
    defaults,
    type === 'create' ? create : update,
    schema,
  )

  watch(apiErrors, () => {
    if (apiErrors.value) {
      // toast.error(
      //   `An issue occurred while ${type === 'create' ? 'creating' : 'updating'} the ${name}`,
      // ) //unsure if this is still needed
      console.error(apiErrors.value)
      errors.value = formatErrors(apiErrors.value)
      isSaving.value = false
    }
  })

  watch(isDirty, (value) => {
    formStore.setDirty(value)
  })

  return {
    form,
    submit,
    isSaving,
    defaults,
    errors: errors || apiErrors,
  }
}
