<script setup lang="ts">
  import type { NuxtError } from '#app'

  const props = defineProps({
    error: Object as () => NuxtError,
  })

  const handleError = () => clearError({ redirect: '/' })
  const { t } = useI18n()

  const errorTitle = computed(() => {
    switch (props.error.statusCode) {
      case 404:
        return t('error.title.notFound')
      case 403:
        return t('error.title.forbidden')
      case 401:
        return t('error.title.unauthorized')
      case 400:
        return t('error.title.badRequest')
      default:
        return t('error.title.serverError')
    }
  })

  useHead({
    title: errorTitle,
  })
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="flex flex-col justify-center items-center">
        <div class="max-w-md w-full space-y-8">
          <div class="flex flex-col items-center space-y-4">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-primary">
              {{ props.error?.statusCode }}
            </h2>
            <p class="mt-2 text-center text-xl text-gray-600">
              {{ props.error?.message ?? t('error.message.notFound') }}
            </p>
            <BasicBtn @click="handleError" size="regular">
              {{ t('error.returnToHome') }}
            </BasicBtn>
          </div>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
