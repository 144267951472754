import { useApiClient } from '~/composables/api/api'
import { z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import type { UnitResponse } from '~/clients'
import { useQueryClient } from '@tanstack/vue-query'
import _ from 'lodash'

const schema = z
  .object({
    id: z.number(),
    code: z.string().min(1),
    name: z.object({
      en: z.string().min(1),
      nl: z.string().min(1).optional(),
      de: z.string().min(1).optional(),
    }),
    description: z.object({
      en: z.string().min(1),
      nl: z.string().min(1).optional(),
      de: z.string().min(1).optional(),
    }),
  })
  .transform((data) => ({
    id: data.id,
    code: data.code,
    nameAsJson: data.name,
    descriptionAsJson: data.description,
  }))

export const useUpdateUnit = async (id: number) => {
  const formStore = useFormStore()

  const queryClient = useQueryClient()
  const { data: unit } = useGetUnit(id)

  await queryClient.ensureQueryData({ queryKey: ['units', id] })

  if (unit.value === null)
    throw createError({
      message: 'Unit has not been found. make sure to useGetUnit in a parent',
    })

  const data = reactive<z.input<typeof schema>>({
    id: unit.value!.id,
    code: unit.value!.code,
    name: _.clone(unit.value!.nameAsJson), // clone to avoid reactivity issues
    description: _.clone(unit.value!.descriptionAsJson), // clone to avoid reactivity issues
  })

  const isDirty = ref(false)

  watch(isDirty, () => {
    formStore.setDirty(isDirty.value)
  })

  watch(
    data,
    () => {
      isDirty.value = true
    },
    { deep: true },
  )

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation(
    {
      mutationFn: async (data: z.input<typeof schema>) => {
        const validatedData = schema.parse(data)

        return await useApiClient<UnitResponse>(`/units/${data.id}`, {
          method: 'PATCH',
          body: validatedData,
        })
      },
      async onSuccess(data) {
        await queryClient.invalidateQueries({
          queryKey: ['units', data.id],
        })
        formStore.reset()
      },
    },
    queryClient,
  )

  const errors = computed<z.inferFormattedError<typeof schema>>(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError) return mutateError.value.data

    return mutateError.value
  })

  const submit = async () => await mutateAsync(data)

  return {
    data,
    submit,
    isPending,
    isDirty,
    error: errors,
  }
}
