import { useApiClient } from '~/composables/api/api'
import { z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import type { UnitResponse } from '~/clients'
import _ from 'lodash'

const schema = z
  .object({
    code: z.string().min(1),
    name: zodTranslation('name is required'),
    description: zodTranslation('description is required'),
  })
  .transform((data) => ({
    code: data.code,
    nameAsJson: data.name,
    descriptionAsJson: data.description,
  }))

export const useCreateUnit = () => {
  const router = useRouter()
  const formStore = useFormStore()

  const data = reactive({
    code: '',
    name: { en: '', nl: undefined, de: undefined },
    description: { en: '', nl: undefined, de: undefined },
  })

  const isDirty = ref(false)

  watch(
    data,
    () => {
      isDirty.value = true
    },
    { deep: true },
  )

  watch(isDirty, () => {
    formStore.setDirty(isDirty.value)
  })

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation({
    mutationFn: async (data: z.input<typeof schema>) => {
      const validatedData = schema.parse(data)

      return await useApiClient<UnitResponse>('/units', {
        method: 'POST',
        body: validatedData,
      })
    },
    async onSuccess(data) {
      await navigateTo(`/hub/management/units/${data.id}`)
    },
  })

  const errors = computed(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError)
      return _.mapKeys(mutateError.value.data, (v, k) => _.camelCase(k))

    return mutateError.value
  })

  const submit = async () => {
    await mutateAsync(data)
  }

  return {
    data,
    submit,
    isPending,
    isDirty,
    error: errors,
  }
}
