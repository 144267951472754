export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    items: [] as any[],
    lockNavigation: ref(localStorage.getItem('lockNavigation') === 'true'), // Initialize from localStorage
  }),
  actions: {
    setItems(items: any[]) {
      this.items = items
    },
    // Update the action to set lockNavigation
    setLockNavigation(value: boolean) {
      this.lockNavigation = value
      localStorage.setItem('lockNavigation', value.toString())
    },
    toggleLockNavigation() {
      this.setLockNavigation(!this.lockNavigation)
    },
  },
})
