import { useApiClient } from '~/composables/api/api'
import { z } from 'zod'
import { useQueryClient } from '@tanstack/vue-query'
import _ from 'lodash'

const schema = z.array(
  z
    .object({
      locationId: z.number(),
      monitoringGroupId: z.number(),
      startDate: z.number().optional().nullable(),
      endDate: z.number().optional().nullable(),
    })
    .transform((data) => {
      return {
        locationId: data.locationId,
        monitoringGroupId: data.monitoringGroupId,
        startDate: data?.startDate && millisecondsToDateOnly(data.startDate),
        endDate: data?.endDate && millisecondsToDateOnly(data.endDate),
      }
    }),
)

export const useCreateMonitoringGroupsLocations = (monitoringGroupId: number | null) => {
  const formStore = useFormStore()
  const queryClient = useQueryClient()
  const route = useRoute()

  const isPending = ref(false)
  let submitErrors = ref([])

  const data = reactive<z.input<typeof schema>>([])

  const isDirty = ref(false)
  watch(isDirty, () => {
    formStore.setDirty(isDirty.value)
  })
  watch(
    data,
    () => {
      isDirty.value = true
    },
    { deep: true },
  )

  const { mutateAsync: addItem, error: addItemError } = useMutation(
    {
      mutationFn: async (item) => {
        return useApiClient(`/portal/group-manager/locations-monitoring-groups`, {
          method: 'POST',
          body: item,
        })
      },
    },
    queryClient,
  )

  // const { mutateAsync: removeItem, error: removeItemError } = useMutation(
  //   {
  //     mutationFn: async (item) => {
  //       return useApiClient(
  //         `/portal/locations-monitoring-groups/${item.id}`,
  //         {
  //           method: 'DELETE',
  //         },
  //       )
  //     },
  //   },
  //   queryClient,
  // )

  const submit = async (data: CreateLocationsMonitoringGroupRequest[]) => {
    isPending.value = true

    const validatedData = schema.safeParse(data)

    if (!validatedData.success) {
      isPending.value = false
      submitErrors = validatedData.error.errors
      return
    }

    const addPromises = validatedData.data.map(addItem)
    const result = await Promise.allSettled([...addPromises])

    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: ['monitoring-group-locations'],
      }),
      queryClient.invalidateQueries({
        queryKey: ['portal', 'locations'],
      }),
    ])

    const errors = result.filter((r) => r.status === 'rejected')
    if (errors.length > 0) {
      isPending.value = false
      submitErrors = errors
      throw errors
    }

    isPending.value = false

    formStore.reset()
    return await navigateTo(
      `/portal/risk-management/monitoring-database/management-group-members?monitoringGroup=${monitoringGroupId}`,
    )
  }

  return {
    submit,
    isPending,
    isDirty,
    error: [],
    submitErrors,
  }
}
