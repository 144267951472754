export const useGetPortalAnalysisSet = (id: number) => {
  return useQuery({
    queryKey: ['analysis-set', id],
    queryFn: async () => {
      return await fetchPortalAnalysisSet(id)
    },
  })
}

export async function fetchPortalAnalysisSet(id: number): Promise<AnalysisSetResponse> {
  return useApiClient(`/portal/analysis-sets/${id}`, {
    method: 'GET',
  })
}
