import { useApiClient } from '~/composables/api/api'
import { date, z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import _ from 'lodash'
import { useQueryClient } from '@tanstack/vue-query'

const schema = z
  .object({
    productId: z.number().min(1),
    locationId: z.string(),
    number: z.number().min(1),
    sampleType: z.nativeEnum(CreatePlannedSampleRequestSampleType),
    plannedSampleNumber: z.number().optional().nullable(),
    year: z.number().refine((val) => val >= 2023 && val <= 2100, {
      message: 'Year must be a 4 digit number',
    }),
    quarter: z.number().optional().nullable(),
    month: z.number().optional().nullable(),
    date: z.date().optional().nullable(),
    combinedAnalysisAssignments: z.array(
      z.object({
        hazardId: z.number().min(1),
        detectionBorderValue: z.number().nullable(),
      }),
    ),
  })
  .transform((data) => ({
    ...data,
    date: data.date ? new Date(data.date) : undefined,
  }))

export const useUpdatePlannedSample = async (id: string) => {
  const formStore = useFormStore()

  const queryClient = useQueryClient()

  const { data: samples } = useGetPortalSample(id, 'planned-samples')

  await queryClient.ensureQueryData({ queryKey: ['planned-samples', id] })

  const data = reactive({
    productId: samples.value?.product,
    locationId: samples.value?.locationId?.toString(),
    plannedSampleNumber: samples.value?.plannedSampleNumber,
    sampleType: samples.value?.sampleType,
    year: samples.value?.year,
    quarter: samples.value?.quarter,
    month: samples.value?.month,
    date: samples.value?.date,
    combinedAnalysisAssignments: samples.value?.combinedAnalysisAssignments.map((assignment) => {
      return {
        ...assignment.hazard,
        detectionBorderValue: assignment.detectionBorderValue,
      }
    }),
  })

  const isDirty = ref(false)

  watch(
    data,
    () => {
      isDirty.value = true
    },
    { deep: true },
  )

  watch(isDirty, () => {
    formStore.setDirty(isDirty.value)
  })

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation(
    {
      mutationFn: async (data: z.input<typeof schema>) => {
        const dataToValidate = {
          ...data,
          id: id,
          productId: data.productId?.id,
          combinedAnalysisAssignments: data.combinedAnalysisAssignments.map((assignment) => {
            return {
              hazardId: assignment.id,
              detectionBorderValue: assignment.detectionBorderValue,
            }
          }),
        }
        return await useApiClient<Promise<PlannedSampleResponse>>(`/portal/planned-samples/${id}`, {
          method: 'PATCH',
          body: dataToValidate,
        })
      },
      async onSuccess(data) {
        await queryClient.invalidateQueries({
          queryKey: ['planned-samples', id],
        })
        formStore.reset()
      },
    },
    queryClient,
  )

  const errors = computed<z.inferFormattedError<typeof schema>>(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError) return mutateError.value.data

    return mutateError.value
  })

  const submit = async () => await mutateAsync(data)

  return {
    data,
    submit,
    isPending,
    isDirty,
    error: errors,
  }
}
