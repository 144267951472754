import validate from "/home/vsts/work/1/s/src/gmp-swa/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/home/vsts/work/1/s/src/gmp-swa/middleware/01.auth.global.ts";
import _02_45guardian_45global from "/home/vsts/work/1/s/src/gmp-swa/middleware/02.guardian.global.ts";
import _03_45persist_45query_45params_45global from "/home/vsts/work/1/s/src/gmp-swa/middleware/03.persist-query-params.global.ts";
import _04_45redirect_45global from "/home/vsts/work/1/s/src/gmp-swa/middleware/04.redirect.global.ts";
import manifest_45route_45rule from "/home/vsts/work/1/s/src/gmp-swa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45guardian_45global,
  _03_45persist_45query_45params_45global,
  _04_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}