export const useFormStore = defineStore('form', {
  state: () => ({
    showSlidePanel: false,
    isDirty: false,
    showConfirm: false,
  }),
  actions: {
    show() {
      this.showSlidePanel = true
    },
    setDirty(bool: boolean) {
      this.isDirty = bool
    },
    reset() {
      this.showSlidePanel = false
      this.isDirty = false
      this.showConfirm = false
    },
    cancel() {
      if (!this.isDirty) {
        this.reset()
        return
      }
      this.showConfirm = true
    },
    cancelConfirm() {
      this.showConfirm = false
    },
  },
})
