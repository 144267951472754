import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'
import type { CreateProcessStepRequest, ProcessStepResponse } from '~/clients'

const schema = z.object({
  ProcessStepGroupId: z.number(),
  sortOrder: z.number(),
  nameAsJson: zodTranslation('Name is required.'),
  descriptionAsJson: zodTranslation('Description is required.'),
})

export const useManageProcessSteps = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateProcessStepRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<ProcessStepResponse, CreateProcessStepRequest>(
    type,
    id,
    'process-steps',
    defaults,
    'Process Step',
    mutationOptions,
    schema,
  )
}
