<script setup lang="ts">
  import { Environment } from '~/types/Environments'
  import { DialogWrapper } from 'vue3-promise-dialog'

  useHead({
    titleTemplate: (titleChunk) =>
      titleChunk ? `${titleChunk} | GMP+ International` : 'GMP+ International',
  })

  const { hostname } = useRequestURL()
  const { initialize } = useGtag()

  if (hostname === Environment.production) {
    initialize()
  }

  const { locale, setLocale } = useI18n()

  const defaultLocale = useLocalStorage('locale', 'en')
  setLocale(defaultLocale.value)

  watch(locale, (value) => {
    defaultLocale.value = value
  })
</script>

<template>
  <div>
    <NuxtLoadingIndicator
      color="repeating-linear-gradient(to right,#38B769 0%,#1A85C7 50%,var(--dp-primary-color) 100%)"
    />
    <NuxtLayout>
      <NuxtPage />
      <CommandPalette />
    </NuxtLayout>
    <DialogWrapper />
    
  </div>
</template>

<style>
  .layout-enter-active,
  .layout-leave-active {
    transition: all 0.1s;
  }

  .layout-enter-from,
  .layout-leave-to {
    opacity: 0;
    filter: blur(1rem);
  }

  @page {
    size: landscape;
    margin: 12px 48px 12px 48px;
  }
</style>
