import { type ErrorMapCtx, setErrorMap, ZodIssueCode, type ZodIssueOptionalMessage } from 'zod'
import { defineNuxtPlugin, useNuxtApp } from '#imports'

export default defineNuxtPlugin((nuxt) => {
  const { t } = useNuxtApp().$i18n
  setErrorMap((issue: ZodIssueOptionalMessage, ctx: ErrorMapCtx): { message: string } => {
    // Example custom error mapping
    switch (issue.code) {
      case ZodIssueCode.invalid_type:
        if (issue.received === 'null' || issue.received === 'undefined') {
          return { message: t(`errors.required`) }
        }
        return { message: t(`errors.invalid_type`) }
    }

    return { message: ctx.defaultError }
  })
})
