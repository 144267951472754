import { z } from 'zod'

export const zodTranslation = (errorMessage: string, optional = false) =>
  z.object({
    en: optional ? z.string().optional().nullable() : z.string().min(1),
    de: z.string().optional().nullable(),
    nl: z.string().optional().nullable(),
  })

// change this
export const zodTranslations = (errorMessage: string) =>
  z.object({
    en: z.array(z.string()).min(1),
    de: z.array(z.string()).optional().nullable(),
    nl: z.array(z.string()).optional().nullable(),
  })
