import { useApiClient } from '~/composables/api/api'
import { z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import { useQueryClient, useMutation } from '@tanstack/vue-query'

const schema = z
  .object({
    id: z.number(),
    monitoringGroupId: z.number(),
    locationId: z.number().optional(),
    startDate: z.string().optional().nullable(),
    endDate: z.coerce.date().optional().nullable(),
  })
  .transform((data) => {
    return {
      id: data.id,
      monitoringGroupId: data.monitoringGroupId,
      locationId: data.locationId,
      startDate: data?.startDate ?? null,
      endDate: data?.endDate ?? null,
    }
  })

export const useUpdateMonitoringGroupLocations = () => {
  const formStore = useFormStore()
  const queryClient = useQueryClient()

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation({
    mutationFn: async (mutationData: z.input<typeof schema>) => {
      schema.parse(mutationData)
      return await useApiClient<Promise<LocationsMonitoringGroupResponse>>(
        `/portal/group-manager/locations-monitoring-groups/${mutationData.id}`,
        {
          method: 'PATCH',
          body: mutationData,
        },
      )
    },
    onSuccess: async () => {
      // Invalidate the query to re-fetch the analysis set
      await queryClient.invalidateQueries({ queryKey: ['monitoring-group-locations'] })
      await queryClient.invalidateQueries({ queryKey: ['location-monitoring-groups'] })
      formStore.reset()
    },
  })

  const errors = computed(() => {
    if (mutateError.value instanceof ZodError) return mutateError.value.format()
    if (mutateError.value instanceof FetchError) return mutateError.value.data
    return mutateError.value
  })

  const submit = async (data) => await mutateAsync(data)

  return {
    submit,
    isPending,
    errors,
  }
}
