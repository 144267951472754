export const convertLineBreaks = (text: any) => {
  if (typeof text !== 'string') {
    return text
  }
  return text?.replace(/(\\\\r\\\\n|\\\\r|\\\\n)+/g, '\\n') || text
}

export const stringIsJson = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const bytesFormat = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

// Usage:
// console.log(numberFormat('1234567.89')); // Output: "1234567,89"
// console.log(numberFormat(undefined)); // Output: ""
// console.log(numberFormat(null)); // Output: ""
// console.log(numberFormat('abc')); // Output: ""
// console.log(numberFormat(1234567.89)); // Output: "1234567,89"
export const numberFormat = (input: any): string => {
  if (input === undefined || input === null) {
    return ''
  }
  const parsedNumber = Number(input)
  if (isNaN(parsedNumber)) {
    return ''
  }
  return Intl.NumberFormat('nl-NL', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
    maximumSignificantDigits: 20,
    useGrouping: false, // Disable thousands separator
    style: 'decimal',
  }).format(parsedNumber)
}
