import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'
import type { CreateProcessStepGroupRequest, ProcessStepGroupResponse } from '~/clients'
const schema = z.object({
  nameAsJson: zodTranslation('Description is required.'),
  descriptionAsJson: zodTranslation('Description is required.'),
})

export const useManageProcessStepGroups = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateProcessStepGroupRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<ProcessStepGroupResponse, CreateProcessStepGroupRequest>(
    type,
    id,
    'process-step-groups',
    defaults,
    'Process Step Group',
    mutationOptions,
    schema,
  )
}
