import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'
import type { ControlMeasureGroupResponse, CreateControlMeasureGroupRequest } from '~/clients'

const schema = z.object({
  nameAsJson: zodTranslation('Name is required.'),
})

export const useManageControlMeasureGroups = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateControlMeasureGroupRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<ControlMeasureGroupResponse, CreateControlMeasureGroupRequest>(
    type,
    id,
    'control-measure-groups',
    defaults,
    'Control Measure',
    mutationOptions,
    schema,
  )
}
