import { z } from 'zod'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'
import { zodTranslation } from '~/utils'
import type { CreateUnitRequest, UnitResponse } from '~/clients'

const schema = z.object({
  nameAsJson: zodTranslation('Name is required.'),
  code: z.string().min(1).max(10),
  descriptionAsJson: zodTranslation('Description is required.'),
})

export const useManageUnits = (
  type: 'create' | 'update',
  defaults?: MaybeRefDeep<CreateUnitRequest>,
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  return useManageApi<UnitResponse, CreateUnitRequest>(
    type,
    id,
    'units',
    defaults,
    'Unit',
    mutationOptions,
    schema,
  )
}
