import type { HazardResponse } from '~/clients'
import { z } from 'zod'
import { useApiClient } from '~/composables/api/api'
import type { MutationObserverOptions } from '@tanstack/vue-query'
import { useErrors } from '~/composables/useErrors'
import type { MaybeRefDeep } from '@tanstack/vue-query/build/modern/types'

const schema = z.object({
  hazardId: z.number(),
  unitId: z.number(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date().nullable().optional(),
  minimumActionBorderValue: z.number().max(9999999999).optional(),
  maximumActionBorderValue: z.number().max(9999999999).optional(),
  minimumRejectionBorderValue: z.number().max(9999999999).optional(),
  maximumRejectionBorderValue: z.number().max(9999999999).optional(),
})

export const useManageHazardLimits = (
  type: 'create' | 'update',
  id?: number,
  mutationOptions?: MaybeRefDeep<MutationObserverOptions>,
) => {
  const { mutateAsync: updateMutate, error: updateMutateError } = useMutation({
    mutationFn: async (data: z.input<typeof schema>) => {
      const validatedData = schema.parse(data)

      return await useApiClient<HazardResponse>(`/hazard-limits/${id}`, {
        method: 'PATCH',
        body: validatedData,
      })
    },
    ...mutationOptions,
  })

  const { mutateAsync: createMutate, error: createMutateError } = useMutation({
    mutationFn: async (data: z.input<typeof schema>) => {
      const validatedData = schema.parse(data)

      return await useApiClient<HazardResponse>(`/hazard-limits`, {
        method: 'POST',
        body: validatedData,
      })
    },
    ...mutationOptions,
  })

  const updateErrors = useErrors(schema, updateMutateError)
  const createErrors = useErrors(schema, createMutateError)

  const update = async (data: z.input<typeof schema>) => await updateMutate(data)

  const create = async (data: z.input<typeof schema>) => await createMutate(data)

  return {
    mutate: type === 'create' ? create : update,
    errors: type === 'create' ? createErrors : updateErrors,
    schema,
  }
}
