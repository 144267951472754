<script setup lang="ts">
  import {
    DialogContent,
    DialogOverlay,
    DialogPortal,
    DialogRoot,
    ComboboxRoot,
    ComboboxInput,
    ComboboxContent,
    ComboboxEmpty,
    ComboboxViewport,
    ComboboxItem,
    ComboboxAnchor,
  } from 'radix-vue'
  import { HubRoutes, PortalRoutes } from '~/routes/routes'
  import { useMagicKeys } from '../../.nuxt/imports'
  const commandText = ref('')
  const open = ref(false)

  const { t } = useI18n()
  const authStore = useAuthStore()
  const mapRoutes = (routes: any[], addedRoutes: any[] = [], parents: string[] = []) => {
    const outputRoutes: any[] = addedRoutes
    for (const route of routes.filter((x) => authStore.isInRoles(x.auth?.roles ?? []))) {
      if (route.children) {
        mapRoutes(route.children, outputRoutes, [...parents, t(`nav.menu.${route.name}`)])
      } else {
        outputRoutes.push({
          id: route.path,
          name: `${t(`nav.menu.${route.name}`)}`,
          description: route.path,
          action: () => navigateTo(route.path),
        })
      }
    }

    return outputRoutes
  }

  const mappedRoutesToCommands = computed(() => {
    return mapRoutes([...PortalRoutes(t), ...HubRoutes])
  })
  const commands = computed(() => {
    return [...mappedRoutesToCommands.value]
  })

  const onAction = (action: () => void) => {
    action()
    open.value = false
  }

  function keywordSearch(commands: any[], searchTerm: string): any[] {
    // Split the search term into individual words, and trim any extra whitespace
    const searchKeywords = searchTerm.toLowerCase().split(/\s+/).filter(Boolean)

    // Filter the commands based on whether all search words are present in the name
    return commands.filter((command) =>
      searchKeywords.every((keyword) => command.name.toLowerCase().includes(keyword)),
    )
  }

  const filteredCommands = computed(() =>
    isEmpty(commandText.value)
      ? commands.value.slice(0, 5)
      : keywordSearch(commands.value, commandText.value).slice(0, 5),
  )

  const { ctrl_k } = useMagicKeys({
    passive: false,
    onEventFired: (e) => {
      if (e.ctrlKey && e.key === 'k' && e.type === 'keydown') e.preventDefault()
    },
  })

  whenever(ctrl_k, () => (open.value = true))
</script>

<template>
  <div>
    <DialogRoot v-model:open="open">
      <DialogPortal>
        <DialogOverlay class="fixed inset-0 z-[999] bg-black/10 w-screen h-screen" />
        <DialogContent
          class="p-4 w-[600px] fixed z-[1000] top-20 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white rounded-2xl flex flex-col gap-4"
        >
          <ComboboxRoot class="relative" :open="open" v-model:searchTerm="commandText">
            <ComboboxAnchor>
              <ComboboxInput
                @keydown.esc="open = false"
                auto-focus
                placeholder="type a command or search..."
                class="w-full py-2.5 px-3.5 outline-none"
              />
            </ComboboxAnchor>
            <!--            <ComboboxPortal>-->
            <ComboboxContent class="absolute w-full z-[1001] mt-6">
              <ComboboxViewport class="flex flex-col gap-2">
                <ComboboxEmpty
                  class="w-full border p-4 rounded-lg cursor-pointer bg-white flex flex-col gap-2 focus:outline"
                />
                <ComboboxItem
                  class="w-full border-4 p-6 rounded-lg cursor-pointer bg-white flex flex-col gap-2 focus:outline data-[highlighted]:border-cyan-500 data-[highlighted]:shadow-2xl transition-all duration-100"
                  v-for="command in filteredCommands"
                  :value="command.id"
                  :key="command.id"
                  @select.prevent="onAction(command.action)"
                >
                  <span class="first-letter:uppercase">{{ command.name }}</span>
                  <span class="text-disabled-dark text-xs">{{ command.description }}</span>
                </ComboboxItem>
              </ComboboxViewport>
            </ComboboxContent>
          </ComboboxRoot>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  </div>
</template>
